.card-img-overlay {
  position: relative;
}

.card-img-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(252, 243, 243);
  transition: background-color 0.3s;
}

.flex-fill {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-body {
  position: relative;
}

/* Absolutely position the button at the bottom of CardBody */
.button-container {
  position: absolute;
  bottom: 10px;  /* Adjust this value if you want more or less space at the bottom */
  left: 6%;
  transform: translateX(-50%);
}