.about-container {
  padding: 2rem;
  font-family: font1, sans-serif; 
  background-color: #f7f7f7;
  color: #333;
  font-size: 24px;

}

h1, h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.about-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
}

.collapsible-title {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #171717;
  color: #d6d6d6;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}



.about-container {
  border-radius: 15px;
}

.collapsible-title:hover {
  background-color: #333;
  color: #f7f7f7;
}

.about-image {
  width: 100%;
  border-radius: 10px;
}