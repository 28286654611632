
@font-face {
  font-family: 'q';
  src: url('./assets/fonts/q.ttf') format('ttf');
}

@font-face {
  font-family: 'one';
  src: url('./assets/fonts/one.ttf') format('ttf');
}
body {
  background: linear-gradient(-45deg, #245f9a, #122f4b, #152a43, #45abb3);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

body, html {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use viewport height to ensure it covers the entire viewport */
}

.main-content {
  flex: 1 0 auto; /* This ensures that the main content takes up all available space */
}

footer {
  flex-shrink: 0; /* This ensures that the footer doesn't shrink */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}