

.boxstyle:hover {
  box-shadow: 0 0px 12px rgba(255, 255, 255, 0.985);
  transition: box-shadow 0.4s ease-in-out;
}

div { 
  font-family: font1;
}
