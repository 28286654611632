
a {
  color: inherit;
  text-decoration: none;
}
ul li a {
  color: inherit;
  text-decoration: none;
  font-size: 20px;
}
h4 {
  font-size: 20px !important;
}
.site-footer {
  flex-shrink: 0; /* Ensure the footer doesn't shrink */
  width: 100%;
}