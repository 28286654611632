html { 
  font-size: 65%;
}

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

