.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1rem;
}

.brand {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item {
  margin: 0 1rem;
}

.nav-link {
  color: #b6b6b6 !important;
  text-transform: uppercase;
  font-weight: bold;
}

.nav-link:hover {
  color: #e1e1e1 !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 0.25rem;
}

.active {
  color: #ffffff !important;
}


.sidenav {
  height: 100%;
  width: 17vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #171717;
  padding-top: 50px;
  padding-right: 0%;
  display: grid;
  z-index: 9999;
}


.sidenav a {
  padding: 6px 6px 6px 17px;
  text-decoration: none;
  font-size: 2.5vh;
  font-family: font1;
  color: #d6d6d6;
  display: grid;
  align-items: center;
  justify-content: start;
}

.sidenav a:hover {
  color: #5f5f5f;
}

.main {
  margin-left: 200px; 
  
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

@font-face {
  font-family: font1;
  src: url(../../assets/fonts/q.ttf);
};


#headShot {
  justify-content: center;
  align-items:center
};


.sidenav {
  padding: '0.5vw';
  min-height: 200px;
}

@media (min-width: 768px) {
  .sidenav {
    padding: '.5vw';
    min-width: 200px; 
  }
}


.navbar-toggle {
  margin: 10px 20px 0px 50px;
  height: 35px;
  width: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5f5f5f;
}
.navbar-toggle:hover .tooltip {
  background: radial-gradient(circle, #545454, #575757); 
}

.sidenav.collapsed {
  display: none;
}

.sticky {
  position: sticky;
  top: 0;
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.arrow-left {
  border-right: 15px solid rgb(0, 0, 0);
}

.arrow-right {
  border-left: 15px solid rgb(0, 0, 0);
}

.arrow-gap {
  margin-left: 10px;
}

@media (max-width: 600px) { /* Adjust this breakpoint as necessary */
  .sidenav a {
    font-size: 2vh; /* Reduce font size */
    padding: 4px 4px 4px 12px; /* Adjust padding */
  }
}

/* Adjust padding and margin for medium screens */
@media (max-width: 900px) { /* Adjust this breakpoint as necessary */
  .sidenav a {
    font-size: 2.2vh; /* Slightly reduce font size */
    padding: 5px 5px 5px 15px; /* Adjust padding */
  }
}
